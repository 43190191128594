import React, { useEffect, useState } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import Login from "./Pages/Login";
import TopMenu from "./Components/TopMenu";
import theme from "./Theme/theme";
import Reportes from "./Pages/Reportes";
import { Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer";

function App({ msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <TopMenu />

          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Reportes />
          </AuthenticatedTemplate>
          <Footer />
        </ThemeProvider>
      </div>
    </MsalProvider>
  );
}

export default App;
