import React, { useState } from "react";
import Logo from "../Assets/logo-aok.png";
import Button from "@mui/material/Button";
import {
  Typography,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AccountCircle } from "@mui/icons-material/";
import "./TopMenu.scss";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

function TopMenu() {
  //Manejo de cierre de sesión y datos del usuario
  const { instance, accounts } = useMsal();

  const handleLogout = (e) => {
    instance.logoutPopup({ postLogoutRedirectUri: "/" });
  };

  //Handle manu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box>
        <AppBar position="static" color="primary">
          <Toolbar>
            <div className="top-menu">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Puma_Energy_logo.svg/1131px-Puma_Energy_logo.svg.png"
                alt="logo-cliente"
                className="logo-cliente"
              />
              <div className="user-options">
                {accounts.length !== 0 ? (
                  <p>Hola, {accounts[0].name}</p>
                ) : (
                  <p> </p>
                )}

                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>

              {useIsAuthenticated() && (
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
                </Menu>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}

export default TopMenu;
