import React, { useEffect, useState } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import EmbeddedReport from "../Components/EmbeddedReport";
import reportData from "../Assets/reportData.json";
import "./Reportes.scss";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Reportes(props) {
  const { instance, accounts } = useMsal();

  //Se obtiene la info del usuario y de TODOS los reportes
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setRoles(accounts[0].idTokenClaims.roles);
  }, [props]);

  //Se asigna los reportes correspondientes al usuario
  const reportes = reportData.reportes;
  const [reportesDisponibles, setReportesDisponibles] = useState([]);
  useEffect(() => {
    reportes.map((reporte) => {
      if (roles.includes(reporte.name)) {
        setReportesDisponibles((reportesDisponibles) => [
          ...reportesDisponibles,
          reporte,
        ]);
      }
    });
  }, [roles]);

  //Se setea el reporte a mostrar
  const [reporteSeleccionado, setReporteSeleccionado] = useState();

  const selectReport = (e) => {
    setReporteSeleccionado(reportesDisponibles[e.target.id]);
  };

  //Controles del Accordion
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (reportesDisponibles.length > 0) {
    return (
      <div className="reportes">
        <div className="lista-reportes">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              Tus Reportes Disponibles
            </AccordionSummary>
            {reportesDisponibles.map((reporte, i) => {
              return (
                <AccordionDetails key={i}>
                  <p
                    onClick={selectReport}
                    id={i}
                    className="reporte-disponible"
                  >
                    {reporte.title}
                  </p>
                </AccordionDetails>
              );
            })}
          </Accordion>
        </div>

        <div className="mostrar-reporte">
          {reporteSeleccionado ? (
            <EmbeddedReport data={reporteSeleccionado} />
          ) : (
            <h3 className="msj-espera">Seleccione un reporte</h3>
          )}
          {!reportesDisponibles && (
            <h3 className="msj-espera">No tiene ningún reporte disponible. </h3>
          )}
        </div>
      </div>
    );
  } else {
    return <h1>Cargando...</h1>;
  }
}

export default Reportes;
