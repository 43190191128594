import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Typography, Button, Box } from "@mui/material";
import LogoAzure from "../Assets/logo-azure.png";
import "./Login.scss";
import BackgroundImg from "../Assets/bg-img.jpg";

function Login() {
  //Msla Library
  const { instance } = useMsal();
  const handleLogin = (e) => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <div className="login" style={{ backgroundImage: `url(${BackgroundImg})` }}>
      <h1 className="title">Visualiza tus reportes de Power BI</h1>

      <h4 className="subtitle">
        Una forma segura de publicar reportes de Power BI y gestionar accesos.
        Actualización diaria y posibilidad de exportar los datos a Excel.
      </h4>

      <div className="btn-box">
        <h3>Inicia Sesión con tu cuenta de Azure</h3>

        <Button
          data-depth="0.2"
          className="btn-login"
          color="secondary"
          variant="contained"
          onClick={handleLogin}
        >
          <p className="">Iniciar Sesión</p>
        </Button>
        <img data-depth="0.6" className="btn-img" src={LogoAzure} alt="" />
      </div>
    </div>
  );
}

export default Login;
