import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "#e3f2fd",
      main: "#8f8f8f",
      dark: "#42a5f5",
    },
    secondary: {
      light: "#0d8cf3",
      main: "#0d8cf3",
      dark: "#42a5f5",
    },
  },
});

export default theme;
