import React from "react";
import "./Footer.scss";
import LogoAok from "../Assets/logo-aok.png";
import { Box } from "@mui/material";
function Footer() {
  return (
    <div className="footer">
      <p>Servicio de </p>
      <a href="https://www.grupoaoniken.com.ar/" target="_blank">
        Aoniken Servicios IT
      </a>
      <img src={LogoAok} alt="logo-aoniken" height="30px" />
    </div>
  );
}

export default Footer;
