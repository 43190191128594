import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import "./EmbeddedReport.scss";
function EmbeddedReport(props) {
  const { data } = props;

  return (
    <React.Fragment>
      <iframe
        title="HeadOfficePuma-Dashboard"
        src={data.url}
        frameBorder="0"
        allowFullScreen={true}
        className="iframe"
      ></iframe>
    </React.Fragment>
  );
}

export default EmbeddedReport;
